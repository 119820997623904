import React, { Component } from "react"
import Button from "@components/Button/Button"
import PropTypes from "prop-types"
import { getMonthYearFromStrings, sortReviewsByDate } from "../../utils/utils"
import FacewallImage from "./FacewallImage"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ImageMeta from "../ImageMeta"
import BulmaColumns from "../Bulma/BulmaColumns"

var classNames = require("classnames")
var chunk = require("lodash/chunk")

class FixedFacewall extends Component {
  constructor(props) {
    super(props)
  }

  organizeReviews(reviews) {
    let reviewCount = reviews.length
    let nearestFourth = Math.floor(reviewCount / 4) * 4

    if (this.props.sort) {
      reviews = sortReviewsByDate(reviews)
    }

    let reviewChunks
    let slicedReviews
    if (this.props.slice) {
      slicedReviews = reviews.slice(0, nearestFourth)
      reviewChunks = chunk(slicedReviews, 4)
    } else {
      reviewChunks = chunk(reviews, 4)
    }
    return reviewChunks
  }

  render() {
    return (
      <section
        className={classNames("fixed-facewall", this.props.className)}
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        <FacewallHeading
          language={this.props.language}
          heading={this.props.heading}
          blurb={this.props.blurb}
        />
        <FacewallRows
          language={this.props.language}
          onlyFourMobile={this.props.onlyFourMobile}
          reviewChunks={this.organizeReviews(this.props.reviews)}
          isGeneric={this.props.isGeneric}
          hideProcedureNames={this.props.hideProcedureNames}
        />
        {this.props.button && (
          <FacewallButtons language={this.props.language} />
        )}
      </section>
    )
  }
}

FixedFacewall.propTypes = {
  reviews: PropTypes.array.isRequired,
  middleColumnsis: PropTypes.number,
  sideColumnIs: PropTypes.number,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  heading: PropTypes.string,
  blurb: PropTypes.element,
  slice: PropTypes.bool,
  sort: PropTypes.bool
}

function FacewallHeading(props) {
  return (
    <div className="columns has-text-centered-tablet">
      <div className="column is-2"></div>
      <div className="column">
        <h3 style={{ marginBottom: -10 }}>{props.heading}</h3>
        {props.blurb}
        <div style={{ height: "16px" }}></div>
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

function FacewallRows(props) {
  return (
    <div>
      {props.reviewChunks.map((chunk, rowIndex) => (
        <FacewallRow
          isGeneric={props.isGeneric}
          language={props.language}
          onlyFourMobile={props.onlyFourMobile}
          rowIndex={rowIndex}
          key={rowIndex}
          chunk={chunk}
          sideColumnIs={props.sideColumnIs}
          middleColumnsis={props.middleColumnsis}
          hideProcedureNames={props.hideProcedureNames}
        />
      ))}
    </div>
  )
}

FacewallRows.propTypes = {
  reviewChunks: PropTypes.arrayOf(PropTypes.array).isRequired,
  onlyFourMobile: PropTypes.bool
}

function FacewallRow(props) {
  let rowClasses = classNames("procedure-patient-row", {
    "show-phone-only-inverse": props.rowIndex > 0 && props.onlyFourMobile,
    "is-multiline": true
  })
  return (
    <BulmaColumns className={rowClasses}>
      {props.sideColumnIs && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
      {props.chunk.map((review, i) => {
        return (
          <FacewallImage
            key={review.title}
            imageUrl={review.thumbnailPublicId}
            reviewerName={review.reviewerName}
            procedure={
              review.otherProcedure
                ? review.otherProcedureName
                : review.mainProcedureDone
            }
            procedureEs={review.mainProcedureDoneEs}
            isGeneric={props.isGeneric}
            url={review.title}
            monthYear={review.monthYear}
            middleColumnsis={props.middleColumnsis}
            language={props.language}
            i={i}
            hideProcedureNames={props.hideProcedureNames}
          />
        )
      })}
      {props.sideColumnIs && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
    </BulmaColumns>
  )
}

FacewallRow.propTypes = {
  chunk: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onlyFourMobile: PropTypes.bool
}

// function FacewallImage(props) {
//   let url = props.url;
//   if (url.charAt(0) !== "/") url = "/" + url;
//   return (
//     <>
//       <div className="column fixed-facewall-item has-text-centered">
//         <Link to={url.toLowerCase()}>
//           <ImageMeta
//             cloudName="nuvolum"
//             publicId={props.imageUrl}
//             width="auto"
//             responsive
//             responsiveUseBreakpoints="true"
//           >

//           </ImageMeta>
//         </Link>
//         <Link className="image-caption-link" to={url.toLowerCase()}>
//           <div className="image-caption-wrapper">
//             {/* <h6 className="image-caption staff fixed-facewall-name">Meet {props.reviewerName}</h6>
//             <h6 className="image-caption staff staff-title light fixed-facewall-time">
//               {getMonthYearFromStrings(
//                 props.monthYear.year,
//                 props.monthYear.month
//               )}
//             </h6> */}
//             <h4 className="" style={{ margin: 0, marginBottom: "0" }}>{props.language === "es" ? "Conoce a" : "Meet"} {props.reviewerName}</h4>
//             <p className="" style={{ margin: 0, marginBottom: "1.563rem", fontWeight: "normal" }}>
//               {getMonthYearFromStrings(
//                 props.monthYear.year,
//                 props.monthYear.month
//               )}
//             </p>
//           </div>
//         </Link>
//       </div>
//       {props.i !== 3 && <div className={`column is-${props.middleColumnsis}`}></div>}
//     </>
//   )
// }

function FacewallButtons({ language }) {
  return (
    <div className="columns has-text-centered">
      <div className="column">
        {language === "es" ? (
          <Button
            contained
            buttonText="Más Pacientes"
            href="/es/historias-de-pacientes/"
          />
        ) : (
          <Button contained buttonText="More Patients" href="/video-reviews/" />
        )}
      </div>
    </div>
  )
}

export default FixedFacewall
