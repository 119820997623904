import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ImageMeta from "../ImageMeta"

var classNames = require("classnames")

import MarkdownViewer from "@components/MarkdownViewer"

class TheTeamApproach extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section
        className="body-sections section joshua-tree-content the-team-approach"
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        <div className="columns">
          <div className="column is-6"></div>
          <div className="column">
            <h3>{this.props.heading}</h3>
          </div>
        </div>
        <div className="columns">
          <div className="column is-6"></div>
          <div className="column is-8">
            <MarkdownViewer className="column" markdown={this.props.text} />
          </div>
          <div className="column">
            <ImageMeta
              className="diagram"
              cloudName="nuvolum"
              publicId={this.props.image}
              width="auto"
              responsive></ImageMeta>
          </div>
          <div className="column is-5"></div>
        </div>
        <div className="columns">
          <div className="column is-6"></div>
          <div className="column">
            {this.props.checks.map(check => (
              <div key={check} className="check">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId="PNW/DEV/smile-complete-check-mark.svg"
                  width="auto"
                  responsive></ImageMeta>
                <p className="large">{check}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

TheTeamApproach.propTypes = {
  textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number
}

export default TheTeamApproach
