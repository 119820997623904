import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ImageMeta from "../ImageMeta"

var classNames = require("classnames")

import MarkdownViewer from "@components/MarkdownViewer"

class EzPro extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section
        className="body-sections section joshua-tree-content ez-pro color-back"
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        <div className="columns is-centered">
          <div className="column">
            <ImageMeta
              className="main-logo"
              cloudName="nuvolum"
              publicId="PNW/DEV/ezpro-logo.svg"
              width="auto"
              responsive></ImageMeta>
          </div>
        </div>

        <div className="columns text">
          <div className="column is-5"></div>
          <div className="column">
            <MarkdownViewer markdown={this.props.text} />
          </div>
          <div className="column is-5"></div>
        </div>

        <div className="columns is-desktop">
          <div className="column is-5"></div>
          <SmallSlideColumn
            heading="Anterior Implants"
            images={[
              "PNW/DEV/ezpro-anterior-1.jpg",
              "PNW/DEV/ezpro-anterior-2.jpg"
            ]}
            anterior
          />
          <div className="column is-1"></div>
          <SmallSlideColumn
            heading="Posterior Implants"
            images={[
              "PNW/DEV/ezpro-posterior-1.jpg",
              "PNW/DEV/ezpro-posterior-2.jpg"
            ]}
          />
          <div className="column is-5"></div>
        </div>

        <div className="columns is-desktop">
          <div className="column is-5"></div>
          <div className="column">
            <LargeSlideColumn />
          </div>

          <div className="column is-5"></div>
        </div>
      </section>
    )
  }
}

EzPro.propTypes = {
  textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number
}

export default EzPro

function SmallSlideColumn({ heading, images, anterior }) {
  return (
    <div className="column slide-col slide-col-sm has-text-centered">
      <h5>{heading}</h5>
      <div className={`images${anterior ? " anterior" : " posterior"}`}>
        <ImageMeta
          cloudName="nuvolum"
          publicId={images[0]}
          width="auto"
          responsive></ImageMeta>
        <ImageMeta
          cloudName="nuvolum"
          publicId={images[1]}
          width="auto"
          responsive></ImageMeta>
      </div>
    </div>
  )
}

function LargeSlideColumn() {
  return (
    <div className="column slide-col-lg-wrapper">
      <div className="slide-col slide-col-lg">
        <div className="row">
          <h5>Traditional Healing Abutment</h5>
          <ImageMeta
            cloudName="nuvolum"
            className="first"
            publicId="PNW/DEV/ezpro-traditional-healing-1.jpg"
            width="auto"
            responsive></ImageMeta>
          <ImageMeta
            cloudName="nuvolum"
            className="second"
            publicId="PNW/DEV/ezpro-traditional-healing-2.jpg"
            width="auto"
            responsive></ImageMeta>
          <ImageMeta
            className="last"
            cloudName="nuvolum"
            publicId="PNW/DEV/ezpro-traditional-healing-3.jpg"
            width="auto"
            responsive></ImageMeta>
          <h5>
            ezPRO Anatomical Shaped Tissue
            <br />
            Former
          </h5>
          <ImageMeta
            cloudName="nuvolum"
            className="first"
            publicId="PNW/DEV/ezpro-tanatomical-1.jpg"
            width="auto"
            responsive></ImageMeta>
          <ImageMeta
            cloudName="nuvolum"
            className="second"
            publicId="PNW/DEV/ezpro-tanatomical-2.jpg"
            width="auto"
            responsive></ImageMeta>
          <ImageMeta
            className="last"
            cloudName="nuvolum"
            publicId="PNW/DEV/ezpro-tanatomical-3.jpg"
            width="auto"
            responsive></ImageMeta>
        </div>
      </div>
    </div>
  )
}
